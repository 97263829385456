<template>
  <div
    v-show="isOpen"
    :class="{
      'ap-modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50': true,
      'is-visible': isOpen
    }"
    style="z-index: 100;"
    @click.self="closeModal">
    <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
      <form
        v-if="isOpen"
        id="shippingDataForm"
        class="form-group w-full mb-4"
        @submit.prevent.stop="submitForm"
        @keydown.enter.prevent.stop="submitForm"
        @keypress.enter.prevent.stop="submitForm">
        <strong class="uppercase block mb-5">{{ $t('components.cart.add_new_shipping_address') }}</strong>
        <div class="form-group w-full mb-4">
          <form-label
            :label="`${$t('components.account.form.street')} *`"
            for-id="form_field_street"
            extra-class="block mb-2 hidden"/>
          <form-input
            v-model="form.street"
            :value="form.street"
            :required="true"
            :placeholder="`${$t('components.account.form.street')} *`"
            name="shipping_street"/>
        </div>
        <div class="form-group w-full mb-4">
          <form-label
            :label="`${$t('components.account.form.house_number')} *`"
            for-id="form_field_house_number"
            extra-class="block mb-2 hidden"/>
          <form-input
            v-model="form.number"
            :value="form.number"
            :required="true"
            :placeholder="`${$t('components.account.form.house_number')} *`"
            name="shipping_house_number"/>
        </div>
        <div class="form-group w-full mb-4">
          <form-label
            :label="`${$t('components.account.form.postal_code')} *`"
            for-id="form_field_postal_code"
            extra-class="block mb-2 hidden"/>
          <form-input
            v-model="form.postal_code"
            :value="form.postal_code"
            :required="true"
            :placeholder=" `${$t('components.account.form.postal_code')} *`"
            name="shipping_postal_code"/>
        </div>
        <div class="form-group w-full mb-4">
          <form-label
            :label="`${$t('components.account.form.city')} *`"
            for-id="form_field_city"
            extra-class="block mb-2 hidden"/>
          <form-input
            v-model="form.city"
            :value="form.city"
            :required="true"
            :placeholder="`${$t('components.account.form.city')} *`"
            name="shipping_city"/>
        </div>
        <div class="form-group w-full mb-4">
          <form-label
            :label="`${$t('components.account.form.country')} *`"
            for-id="form_field_country"
            extra-class="block mb-2 hidden"/>
          <div class="form_item">
            <select
              id="country"
              v-model="form.country"
              name="country"
              class="form-select"
              :placeholder="$t('components.account.form.country_select')"
              required>
              <option value="">{{ $t('components.account.form.country_select') }}</option>
              <option
                v-for="(item, itemIndex) in countriesList"
                :key="'country-2-option-' + itemIndex"
                :selected="item.value == form.country"
                :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="isRequestError"
          class="my-4 bg-orange text-white px-4 py-2">
          {{ $t('components.cart.save_shipping_addresses_error') }}
        </div>

        <div class="flex flex-wrap justify-between mt-5">
          <button
            class="button button--secondary--without-skew"
            @click="closeModal">
            <span class="uppercase text-gray-dark">{{ $t('form.cancel') }}</span>
          </button>
          <button
            class="button button--secondary--without-skew"
            type="submit">
            <span class="uppercase">{{ $t('form.save') }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Countries from '/helpers/Countries';
import portal from '/service/portal';

export default {
  name: 'ShippingDataModal',
  props: {
    isOpen: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  computed: {
    countriesList() {
      let userCountry = this.$auth.user.country;
      let countries = Countries.map(country => {
        if (country.value === userCountry) {
          return { ...country, default: true };
        }
        return { ...country, default: false };
      });
      return countries;
    },
  },
  data() {
    return {
      form: {
        city: '',
        country: '',
        number: '',
        postal_code: '',
        street: ''
      },
      isRequestError: false
    }
  },
  methods: {
    closeModal (newAddress) {
      this.$emit('closeModal', newAddress);
    },
    submitForm () {
      this.$set(this, 'isRequestError', false);

      portal.post(
        'customers/add-new-address',
        this.form,
        {
          headers: {
            'Authorization': this.$auth.$storage._state._token_local,
          }
        })
      .then((response) => {
        if (response.status !== 200 || !response.data.success || !response.data.shippingAddress) {
          this.$set(this, 'isRequestError', true);
          throw new Error('Shipping address submit failed');
        }

        this.closeModal(response.data.shippingAddress)
      })
      .catch((error) => {
        console.log(error);
        this.$set(this, 'isRequestError', true);
      })
    }
  }
}
</script>

<style lang="scss">
.ap-modal {
  opacity: 0;
  transition: all 0.25s ease-in-out;
  color: black;

  &.is-visible {
    opacity: 1;
  }
}
</style>
